<template>
  <div class="main">
    <div class="main__background" id="top">
        <img class="main__background--img" src="../../assets/textures/azul.png" alt="paint" />
    </div>
    <div class="main__header">
      <h2 class="main__header--title">GASTRONOMÍA</h2>
    </div>
    <div class="main__gallery">
      <div class="gallery__content">
        <img class="gallery__content--img" v-for="(image, i) in pageOfItems" :src="image" :key="i" @click="index = i">
      </div>
    </div>
    <div class="main__pagination">
      <a href="#top">
        <jw-pagination :items="images" @changePage="onChangePage"></jw-pagination>
      </a>
    </div>
    <vue-gallery-slideshow :images="pageOfItems" :index="index" @close="index = null"></vue-gallery-slideshow>
    <div class="main__footer">
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import VueGallerySlideshow from 'vue-gallery-slideshow';
import Footer from '../../components/Footer.vue';

/**IMAGENES */

/** ---------------------PAGE 1----------------------- */
import G2 from '../../assets/images/Fotografía/Gastronomía/2.jpg';
import G1 from '../../assets/images/Fotografía/Gastronomía/1.jpg';
import G3 from '../../assets/images/Fotografía/Gastronomía/3.jpg';
import G4 from '../../assets/images/Fotografía/Gastronomía/4.jpg';
import G5 from '../../assets/images/Fotografía/Gastronomía/5.jpg';
import G6 from '../../assets/images/Fotografía/Gastronomía/6.jpg';
import G7 from '../../assets/images/Fotografía/Gastronomía/7.jpg';
import G8 from '../../assets/images/Fotografía/Gastronomía/8.jpg';
import G9 from '../../assets/images/Fotografía/Gastronomía/9.jpg';
import G10 from '../../assets/images/Fotografía/Gastronomía/10.jpg';
import G11 from '../../assets/images/Fotografía/Gastronomía/11.jpg';
import G12 from '../../assets/images/Fotografía/Gastronomía/12.jpg';

/** ---------------------PAGE 2----------------------- */
import G13 from '../../assets/images/Fotografía/Gastronomía/13.jpg';
import G14 from '../../assets/images/Fotografía/Gastronomía/14.jpg';
import G15 from '../../assets/images/Fotografía/Gastronomía/15.jpg';
import G16 from '../../assets/images/Fotografía/Gastronomía/16.jpg';
import G17 from '../../assets/images/Fotografía/Gastronomía/17.jpg';
import G18 from '../../assets/images/Fotografía/Gastronomía/18.jpg';
import G19 from '../../assets/images/Fotografía/Gastronomía/19.jpg';
import G20 from '../../assets/images/Fotografía/Gastronomía/20.jpg';
import G21 from '../../assets/images/Fotografía/Gastronomía/21.jpg';
import G22 from '../../assets/images/Fotografía/Gastronomía/22.jpg';
import G23 from '../../assets/images/Fotografía/Gastronomía/23.jpg';
import G24 from '../../assets/images/Fotografía/Gastronomía/24.jpg';

/** ---------------------PAGE 3----------------------- */
import G25 from '../../assets/images/Fotografía/Gastronomía/25.jpg';
import G26 from '../../assets/images/Fotografía/Gastronomía/26.jpg';
import G27 from '../../assets/images/Fotografía/Gastronomía/27.jpg';
import G28 from '../../assets/images/Fotografía/Gastronomía/28.jpg';
import G29 from '../../assets/images/Fotografía/Gastronomía/29.jpg';
import G30 from '../../assets/images/Fotografía/Gastronomía/30.jpg';
import G31 from '../../assets/images/Fotografía/Gastronomía/31.jpg';
import G32 from '../../assets/images/Fotografía/Gastronomía/32.jpg';
import G33 from '../../assets/images/Fotografía/Gastronomía/33.jpg';
import G34 from '../../assets/images/Fotografía/Gastronomía/34.jpg';
import G35 from '../../assets/images/Fotografía/Gastronomía/35.jpg';
import G36 from '../../assets/images/Fotografía/Gastronomía/36.jpg';

/** ---------------------PAGE 4----------------------- */
import G37 from '../../assets/images/Fotografía/Gastronomía/37.jpg';
import G38 from '../../assets/images/Fotografía/Gastronomía/38.jpg';
import G39 from '../../assets/images/Fotografía/Gastronomía/39.jpg';
import G40 from '../../assets/images/Fotografía/Gastronomía/40.jpg';
import G41 from '../../assets/images/Fotografía/Gastronomía/41.jpg';
import G42 from '../../assets/images/Fotografía/Gastronomía/42.jpg';
import G43 from '../../assets/images/Fotografía/Gastronomía/43.jpg';
import G44 from '../../assets/images/Fotografía/Gastronomía/44.jpg';
import G45 from '../../assets/images/Fotografía/Gastronomía/45.jpg';
import G46 from '../../assets/images/Fotografía/Gastronomía/46.jpg';
import G47 from '../../assets/images/Fotografía/Gastronomía/47.jpg';
import G48 from '../../assets/images/Fotografía/Gastronomía/48.jpg';

/** ---------------------PAGE 5----------------------- */
import G49 from '../../assets/images/Fotografía/Gastronomía/49.jpg';
import G50 from '../../assets/images/Fotografía/Gastronomía/50.jpg';
import G51 from '../../assets/images/Fotografía/Gastronomía/51.jpg';
import G52 from '../../assets/images/Fotografía/Gastronomía/52.jpg';
import G53 from '../../assets/images/Fotografía/Gastronomía/53.jpg';
import G54 from '../../assets/images/Fotografía/Gastronomía/54.jpg';
import G55 from '../../assets/images/Fotografía/Gastronomía/55.jpg';
import G56 from '../../assets/images/Fotografía/Gastronomía/56.jpg';
import G57 from '../../assets/images/Fotografía/Gastronomía/57.jpg';
import G58 from '../../assets/images/Fotografía/Gastronomía/58.jpg';
import G59 from '../../assets/images/Fotografía/Gastronomía/59.jpg';
import G60 from '../../assets/images/Fotografía/Gastronomía/60.jpg';

/** ---------------------PAGE 6----------------------- */
import G61 from '../../assets/images/Fotografía/Gastronomía/61.jpg';
import G62 from '../../assets/images/Fotografía/Gastronomía/62.jpg';
import G63 from '../../assets/images/Fotografía/Gastronomía/63.jpg';
import G64 from '../../assets/images/Fotografía/Gastronomía/64.jpg';
import G65 from '../../assets/images/Fotografía/Gastronomía/65.jpg';
import G66 from '../../assets/images/Fotografía/Gastronomía/66.jpg';
import G67 from '../../assets/images/Fotografía/Gastronomía/67.jpg';
import G68 from '../../assets/images/Fotografía/Gastronomía/68.jpg';
import G69 from '../../assets/images/Fotografía/Gastronomía/69.jpg';
import G70 from '../../assets/images/Fotografía/Gastronomía/70.jpg';
import G71 from '../../assets/images/Fotografía/Gastronomía/71.jpg';
import G72 from '../../assets/images/Fotografía/Gastronomía/72.jpg';

/** ---------------------PAGE 7----------------------- */
import G73 from '../../assets/images/Fotografía/Gastronomía/73.jpg';
import G74 from '../../assets/images/Fotografía/Gastronomía/74.jpg';
import G75 from '../../assets/images/Fotografía/Gastronomía/75.jpg';
import G76 from '../../assets/images/Fotografía/Gastronomía/76.jpg';
import G77 from '../../assets/images/Fotografía/Gastronomía/77.jpg';
import G78 from '../../assets/images/Fotografía/Gastronomía/78.jpg';
import G79 from '../../assets/images/Fotografía/Gastronomía/79.jpg';
import G80 from '../../assets/images/Fotografía/Gastronomía/80.jpg';
import G81 from '../../assets/images/Fotografía/Gastronomía/81.jpg';
import G82 from '../../assets/images/Fotografía/Gastronomía/82.jpg';
import G83 from '../../assets/images/Fotografía/Gastronomía/83.jpg';
import G84 from '../../assets/images/Fotografía/Gastronomía/84.jpg';

/** ---------------------PAGE 8----------------------- */
import G85 from '../../assets/images/Fotografía/Gastronomía/85.jpg';
import G86 from '../../assets/images/Fotografía/Gastronomía/86.jpg';
import G87 from '../../assets/images/Fotografía/Gastronomía/87.jpg';
import G88 from '../../assets/images/Fotografía/Gastronomía/88.jpg';
import G89 from '../../assets/images/Fotografía/Gastronomía/89.jpg';
import G90 from '../../assets/images/Fotografía/Gastronomía/90.jpg';
import G91 from '../../assets/images/Fotografía/Gastronomía/91.jpg';
import G92 from '../../assets/images/Fotografía/Gastronomía/92.jpg';
import G93 from '../../assets/images/Fotografía/Gastronomía/93.jpg';
import G94 from '../../assets/images/Fotografía/Gastronomía/94.jpg';
import G95 from '../../assets/images/Fotografía/Gastronomía/95.jpg';
import G96 from '../../assets/images/Fotografía/Gastronomía/96.jpg';

/** ---------------------PAGE 9----------------------- */
import G97 from '../../assets/images/Fotografía/Gastronomía/97.jpg';
import G98 from '../../assets/images/Fotografía/Gastronomía/98.jpg';
import G99 from '../../assets/images/Fotografía/Gastronomía/99.jpg';
import G100 from '../../assets/images/Fotografía/Gastronomía/100.jpg';
import G101 from '../../assets/images/Fotografía/Gastronomía/101.jpg';
import G102 from '../../assets/images/Fotografía/Gastronomía/102.jpg';
import G103 from '../../assets/images/Fotografía/Gastronomía/103.jpg';
import G104 from '../../assets/images/Fotografía/Gastronomía/104.jpg';
import G105 from '../../assets/images/Fotografía/Gastronomía/105.jpg';
import G106 from '../../assets/images/Fotografía/Gastronomía/106.jpg';
import G107 from '../../assets/images/Fotografía/Gastronomía/107.jpg';
import G108 from '../../assets/images/Fotografía/Gastronomía/108.jpg';

/** ---------------------PAGE 10----------------------- */
import G109 from '../../assets/images/Fotografía/Gastronomía/109.jpg';
import G110 from '../../assets/images/Fotografía/Gastronomía/110.jpg';
import G111 from '../../assets/images/Fotografía/Gastronomía/111.jpg';
import G112 from '../../assets/images/Fotografía/Gastronomía/112.jpg';
import G113 from '../../assets/images/Fotografía/Gastronomía/113.jpg';
import G114 from '../../assets/images/Fotografía/Gastronomía/114.jpg';
import G115 from '../../assets/images/Fotografía/Gastronomía/115.jpg';
import G116 from '../../assets/images/Fotografía/Gastronomía/116.jpg';
import G117 from '../../assets/images/Fotografía/Gastronomía/117.jpg';
import G118 from '../../assets/images/Fotografía/Gastronomía/118.jpg';
import G119 from '../../assets/images/Fotografía/Gastronomía/119.jpg';
import G120 from '../../assets/images/Fotografía/Gastronomía/120.jpg';

/** ---------------------PAGE 11----------------------- */
import G121 from '../../assets/images/Fotografía/Gastronomía/121.jpg';
import G122 from '../../assets/images/Fotografía/Gastronomía/122.jpg';
import G123 from '../../assets/images/Fotografía/Gastronomía/123.jpg';
import G124 from '../../assets/images/Fotografía/Gastronomía/124.jpg';
import G125 from '../../assets/images/Fotografía/Gastronomía/125.jpg';
import G126 from '../../assets/images/Fotografía/Gastronomía/126.jpg';
import G127 from '../../assets/images/Fotografía/Gastronomía/127.jpg';
import G128 from '../../assets/images/Fotografía/Gastronomía/128.jpg';
import G129 from '../../assets/images/Fotografía/Gastronomía/129.jpg';
import G130 from '../../assets/images/Fotografía/Gastronomía/130.jpg';
import G131 from '../../assets/images/Fotografía/Gastronomía/131.jpg';
import G132 from '../../assets/images/Fotografía/Gastronomía/132.jpg';
import G133 from '../../assets/images/Fotografía/Gastronomía/133.jpg';

const customStyles = {
    pageNumber: {
      color: 'blue'
    }
};

export default {
    data () {
      return {
        customStyles,
        pageNumber: 'page-number',
        images: [
          G1,G2,G3,G4,G5,G6,G7,G8,G9,G10,
          G11,G12,G13,G14,G15,G16,G17,G18,G19,G20,
          G21,G22,G23,G24,G25,G26,G27,G28,G29,G30,
          G31,G32,G33,G34,G35,G36,G37,G38,G39,G40,
          G41,G42,G43,G44,G45,G46,G47,G48,G49,G50,
          G51,G52,G53,G54,G55,G56,G57,G58,G59,G60,
          G61,G62,G63,G64,G65,G66,G67,G68,G69,G70,
          G71,G72,G73,G74,G75,G76,G77,G78,G79,G80,
          G81,G82,G83,G84,G85,G86,G87,G88,G89,G90,
          G91,G92,G93,G94,G95,G96,G97,G98,G99,G100,
          G101,G102,G103,G104,G105,G106,G107,G108,G109,G110,
          G111,G112,G113,G114,G115,G116,G117,G118,G119,G120,
          G121,G122,G123,G124,G125,G126,G127,G128,G129,G130,
          G131,G132,G133
        ],
        pageOfItems: [],
        index: null
      }
    },
    methods: {
      onChangePage(pageOfItems) {
        this.pageOfItems = pageOfItems;
      }
    },
    components: {
      VueGallerySlideshow,
      Footer
    } 
}
</script>

<style scoped>
.main__background {
  position: relative;
}
.main__background--img {
  width: 100%;
  height: 570px;
  object-fit: cover;
}
.main__header {
  width: 100%;
  position: absolute;
  color: var(--txt-color);
  text-align: center;
  top: 250px;
  margin: auto;
}
.main__header--title{
  font-family: 'Yeseva One';
  font-size: 70px;
  padding: 9px 0px;
  margin: 2px;
}
.main__header--subtitle{
  font-family: var(--font);
  font-weight: 500;
  padding: 9px 0px;
  line-height: 28px;
  margin: 2px;
}
.main__pagination{
  margin-top: 50px;
  text-align: center;
}
.main__gallery{
  margin-top: -100px;
}
.gallery__content{
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.gallery__content--img{
  width: 500px;
  height: 500px;
  object-fit: cover;
  margin: 20px;
}
.page-number {
  background: chartreuse !important;
}
@media (max-width: 800px){
  .main__header--title{
    font-size: 40px;
  }
  .gallery__content--img {
    width: 200px;
    height: 200px;
  }
}
</style>